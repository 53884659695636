$primary-color: #7f63f4;

.hidden {
  display: none;
}

.main-blue {
  color: $primary-color;
}

.bg-main-blue {
  background-color: $primary-color;
  border: none !important;
}

.bg-main-white {
  background-color: white;
  color: grey;
  border-color: lightgray !important;
  font-size: 0.8rem;
}

.light-mode .leftNav li:hover {
  background: #f1eeff;
}

.light-mode .leftNav:hover {
  width: 170px;
}

.light-mode .leftNav .item-menu {
  height: 50px;
  overflow: hidden;
  color: #7c6ebb;
}

.light-mode .active > li {
  background-color: #f1eeff;
  border-left: 2px solid #7d64fb;
  padding-left: 13px;
}

.light-mode .leftNav a {
  color: white;
  text-decoration: none;
  font-weight: bold;
}
span.menu {
  padding-left: 17px;
}

/**
--------------
Light Mode
--------------
**/
.MuiDialogContent-root,
.MuiButton-root {
  background: #192039 !important;
  color: #c2c2c2 !important;
  position: relative;
}

.light-mode .navbar-brand {
  // display: block;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  background: url('../logo.png') no-repeat;
  background-size: contain;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
  height: 30px; /* Height of new image */
}

.light-mode,
.light-mode .leftNav,
.light-mode .navbar {
  background-color: #fff !important;
  color: #c2c2c2 !important;
}

.light-mode .navbar a {
  // color: black !important;
}

.light-mode.main-content {
  background: #f3f6f9 !important;
  color: #c2c2c2 !important;
  min-height: 100vh !important;
}

.light-mode .pageHeader {
  height: 40px;
  padding-left: 80px;
  padding-top: 6px;
  background: #e7e6f8;
  vertical-align: middle;
  color: #7f63f4;
  font-weight: bold;
}

.light-mode .box-farm {
  min-height: 250px;
  background: #fff;
}

.light-mode .section-title {
  color: #7f63f4;
  font-weight: bold;
}

.light-mode .stake-header-upper-section {
  background-color: #cccccc;
}

.light-mode .stake-header-lower-section {
  // background-color: #fafafa;
}

.stake-header-text {
  color: #7c6ebb;
}

.text-gray:hover {
  color: #abafb3;
}

.light-mode .pool-titles {
  color: #808389;
  font-weight: 600;
}

.light-mode .pool-info {
  color: #808389 !important;
  font-weight: 300;
}

/* hives */
.light-mode .hive-details {
  font-size: 1m;
}

/* exchange */
.light-mode .unit-price {
  color: #424242;
}

.light-mode .whaletank-wrapper {
  color: #454a53 !important;
}
