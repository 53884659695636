.option {
  max-width: 35px;
  max-height: 35px;
  margin-right: 5px;
}

.unit-price {
  font-size: 0.8em;
  color: #424242;
  font-weight: bold;
  margin-left: 15px;
}

.eth-amount-out {
  font-size: 0.8em;
  // color: #424242;
}
