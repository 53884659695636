.option {
  max-width: 35px;
  max-height: 35px;
  margin-right: 5px;
}

.unit-price {
  font-size: 0.8em;
  color: #424242;
  font-weight: bold;
  margin-left: 15px;
}
.exchange-warning {
  border-radius: 4px;
  padding: 0.5rem;
  font-size: 16px;
  background-color: #eb3e18;
  color: #fff;
  display: flex;
  align-items: center;
  font-weight: 700;
  margin-bottom: 5px;

  svg {
    width: 20px;
    height: 20px;
  }
}
