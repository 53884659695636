.videos {
  display: flex;
  flex-wrap: wrap;

  &__item {
    width: 200px;
    margin: 10px;
    transition: transform 0.3s ease;
    box-shadow: 0 0 16px 4px rgba(0, 0, 0, 0.2);

    &:hover {
      transform: scale(1.02) rotate(3deg);
    }
    &:nth-child(odd):hover {
      transform: scale(1.02) rotate(-3deg);
    }
  }
}

.video {
  &__image {
    a {
      display: block;
      width: 100%;
      height: 100%;
    }

    img {
      width: 100%;
      height: 100%;
    }
  }

  &__footer {
    display: flex;
    align-items: center;
    font-size: 12px;
    padding: 10px;
    height: 50px;

    p {
      margin: 0;
      font-weight: 800;
    }
  }
}
